import React from "react";
import Logo from "components/atoms/Logo/Logo.svg";

export default function Header() {
  return (
    <div className="bg-white w-full h-16 shadow-gray flex justify-center px-5 fixed top-0 z-10">
      <img src={Logo} alt="logo" className="self-center" />
    </div>
  );
}
