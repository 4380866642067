import * as React from "react";
import * as Unicons from "@iconscout/react-unicons";
import propTypes from "prop-types";
import "./style.css";

const Icons = (props) => {
  const { iconName, iconSize, iconColor = "" } = props;
  const className = [props.className];
  const icon = React.createElement(Unicons[iconName]);
  return (
    <div className={`${className.join(" ")}`} style={{ color: iconColor, fontSize: iconSize }}>
      {icon}
    </div>
  );
};

Icons.defaultProps = {
  className: "wrap-icon-size",
  iconSize: 16,
};

Icons.propTypes = {
  iconName: propTypes.string,
  iconSize: propTypes.number,
  iconColor: propTypes.string,
};

export default Icons;
