const initialState = {
  isChange: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "ON_CHANGE":
      return { ...state, isChange: action.payload };
    default:
      return state;
  }
}
