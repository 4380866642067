const initialState = {
  listDataShift: null,
  typeAlert: null,
  name: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_DATA_SHIFT":
      return { ...state, listDataShift: action.payload };
    case "SET_TYPE_ALERT_SHIFT":
      return { ...state, typeAlert: action.payload };
    case "SET_NAME_SHIFT":
      return { ...state, name: action.payload };
    default:
      return state;
  }
}
