import { lazy } from "react";
import Layout from "components/templates/app";
import ToStart from "pages/Authentication/Onboard/ToStart";

const Dashboard = lazy(() => import("pages/Dashboard"));
const Employees = lazy(() => import("pages/Identitas/Employees"));
const Visitors = lazy(() => import("pages/Identitas/Visitors"));
const Workshift = lazy(() => import("pages/Identitas/Workshift"));
const Departement = lazy(() => import("pages/Identitas/Departement"));
const EmployeeGroup = lazy(() => import("pages/Identitas/EmployeeGroup"));
const CategoryVisitor = lazy(() => import("pages/Identitas/CategoryVisitor"));
const Devices = lazy(() => import("pages/Devices"));
const Histories = lazy(() => import("pages/Histories"));
const HistoriesVisitor = lazy(() => import("pages/Histories/LogVisitor"));
const Attendance = lazy(() => import("pages/Attendance"));
const Setting = lazy(() => import("pages/Setting"));
const TimeAccess = lazy(() => import("pages/Identitas/AccessHour"));
const Scheduler = lazy(() => import("pages/Identitas/Scheduler"));

const AddEmployee = lazy(() => import("pages/Identitas/Employees/AddEmployee"));
const EditEmployee = lazy(() => import("pages/Identitas/Employees/EditEmployee"));
const ImporEmployee = lazy(() => import("pages/Identitas/Employees/ImportEmployee/listImport"));

const AddVisitor = lazy(() => import("pages/Identitas/Visitors/AddVisitor"));
const EditVisitor = lazy(() => import("pages/Identitas/Visitors/EditVisitor"));

const EditDevice = lazy(() => import("pages/Devices/EditDevice"));
const DetailReport = lazy(() => import("pages/Attendance/DetailReport"));

const ListCodeQR = lazy(() => import("pages/QRCode/ListCodeQR"));
const EmployeeCodeQR = lazy(() => import("pages/QRCode/EmployeeQR"));

const DashboardPage = () => {
  return (
    <Layout>
      <Dashboard />
    </Layout>
  );
};
const EmployeesPage = () => {
  return (
    <Layout>
      <Employees />
    </Layout>
  );
};
const VisitorsPage = () => {
  return (
    <Layout>
      <Visitors />
    </Layout>
  );
};
const CategoryVisitorPage = () => {
  return (
    <Layout>
      <CategoryVisitor />
    </Layout>
  );
};
const TimeAccessPage = () => {
  return (
    <Layout>
      <TimeAccess />
    </Layout>
  );
};
const SchedulerPage = () => {
  return (
    <Layout>
      <Scheduler />
    </Layout>
  );
};
const WorkshiftPage = () => {
  return (
    <Layout>
      <Workshift />
    </Layout>
  );
};
const DepartementPage = () => {
  return (
    <Layout>
      <Departement />
    </Layout>
  );
};
const EmployeeGroupPage = () => {
  return (
    <Layout>
      <EmployeeGroup />
    </Layout>
  );
};
const ListCodeQRPage = () => {
  return (
    <Layout>
      <ListCodeQR />
    </Layout>
  );
};
const EmployeeCodeQRPage = () => {
  return (
    <Layout>
      <EmployeeCodeQR />
    </Layout>
  );
};
const DevicesPage = () => {
  return (
    <Layout>
      <Devices />
    </Layout>
  );
};
const HistoriesPage = () => {
  return (
    <Layout>
      <Histories />
    </Layout>
  );
};
const HistoriesVisitorPage = () => {
  return (
    <Layout>
      <HistoriesVisitor />
    </Layout>
  );
};
const AttendancePage = () => {
  return (
    <Layout>
      <Attendance />
    </Layout>
  );
};
const SettingPage = () => {
  return (
    <Layout>
      <Setting />
    </Layout>
  );
};

const AddEmployeePage = () => {
  return <AddEmployee />;
};

const AddVisitorPage = () => {
  return <AddVisitor />;
};

const EditVisitorPage = () => {
  return <EditVisitor />;
};

const ImportEmployeePage = () => {
  return <ImporEmployee />;
};

const EditDevicePage = () => {
  return <EditDevice />;
};

const DetailReportPage = () => {
  return <DetailReport />;
};

const router = [
  {
    path: "/menyiapkan visuo",
    exact: false,
    component: ToStart,
  },
  {
    path: "/dashboard",
    exact: false,
    component: DashboardPage,
  },
  {
    path: "/daftar karyawan",
    exact: false,
    component: EmployeesPage,
  },
  {
    path: "/daftar pengunjung",
    exact: false,
    component: VisitorsPage,
  },
  {
    path: "/kategori pengunjung",
    exact: false,
    component: CategoryVisitorPage,
  },
  {
    path: "/jam akses pengunjung",
    exact: false,
    component: TimeAccessPage,
  },
  {
    path: "/shift kerja",
    exact: false,
    component: WorkshiftPage,
  },
  {
    path: "/departemen",
    exact: false,
    component: DepartementPage,
  },
  {
    path: "/group data karyawan",
    exact: false,
    component: EmployeeGroupPage,
  },
  {
    path: "/penjadwalan",
    exact: false,
    component: SchedulerPage,
  },
  {
    path: "/daftar kode qr",
    exact: false,
    component: ListCodeQRPage,
  },
  {
    path: "/pendaftaran identitas",
    exact: false,
    component: EmployeeCodeQRPage,
  },
  {
    path: "/perangkat",
    exact: false,
    component: DevicesPage,
  },
  {
    path: "/sejarah keluar-masuk/karyawan",
    exact: false,
    component: HistoriesPage,
  },
  {
    path: "/sejarah keluar-masuk/pengunjung",
    exact: false,
    component: HistoriesVisitorPage,
  },
  {
    path: "/absensi",
    exact: false,
    component: AttendancePage,
  },
  {
    path: "/pengaturan",
    exact: false,
    component: SettingPage,
  },
  {
    path: "/tambah karyawan",
    exact: false,
    component: AddEmployeePage,
  },
  {
    path: "/edit karyawan",
    exact: false,
    component: EditEmployee,
  },
  {
    path: "/impor karyawan",
    exact: false,
    component: ImportEmployeePage,
  },
  {
    path: "/tambah pengunjung",
    exact: false,
    component: AddVisitorPage,
  },
  {
    path: "/edit pengunjung",
    exact: false,
    component: EditVisitorPage,
  },
  {
    path: "/edit perangkat",
    exact: false,
    component: EditDevicePage,
  },
  {
    path: "/laporan-absensi/:id",
    exact: false,
    component: DetailReportPage,
  },
];

export default router;
