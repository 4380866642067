const date = new Date();
const firstDayDef = new Date(date.getFullYear(), date.getMonth(), 1);
const lastDayDef = new Date(date.getFullYear(), date.getMonth() + 1, 0);

const initialState = {
    firstDay : firstDayDef,
    lastDay : lastDayDef
};
  
export default function reducer(state = initialState, action) {
    switch (action.type) {
      case "SET_DATE_FILTER":
        return { ...state, firstDay: action.payload.first, lastDay: action.payload.last };
      default:
        return state;
    }
  }