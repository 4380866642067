import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import shiftReducer from "features/Shift/reducers";
import authReducer from "features/Auth/reducer";
import onChangeSpy from "features/onChangeSpy/reducers";
import employeeReducer from "features/Employee/reducers";
import departementReducer from "features/Departement/reducers";
import historyReducer from "features/Departement/reducers";
import categoryVisitor from "features/CategoryVisitor/reducers";
import attendanceReducer from "features/Attendance/reducers";
import qrCodeReducer from "features/QRCode/reducers";
import otherReducer from "features/Other/reducers";
import accessHourReducer from "features/AccessHour/reducer";
import employeeGroup from "features/EmployeeGroup/reducers"

import thunk from "redux-thunk";

const composerEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducers = combineReducers({
  auth: authReducer,
  shift: shiftReducer,
  onChangeSpy,
  employee: employeeReducer,
  departement: departementReducer,
  history: historyReducer,
  categoryVisitor: categoryVisitor,
  attendance: attendanceReducer,
  qrCode: qrCodeReducer,
  employeeGroup: employeeGroup,
  other: otherReducer,
  accessHour: accessHourReducer,
});

const store = createStore(rootReducers, composerEnhancer(applyMiddleware(thunk)));

// (6) export store
export default store;
