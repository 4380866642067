import dotenv from "dotenv";

dotenv.config();


// const url = `http://13.213.143.174`; //*** */ url development
// const url = `http://52.220.47.77`; //*** */ url staging
// const url = `http://18.142.85.56`
const url = "https://visuodev.dalenta.net"; //*** */ url production //18.142.85.56

const config = {
  api_host: `${url}`,
  api_imageUrl: `${url}/images`,
  api_docUrl: `${url}/docs`,
};

export { config };
