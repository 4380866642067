import * as React from "react";
import { useHistory } from "react-router-dom";
import "./index.css";
import Button from "components/atoms/Button";
import IconClose from "components/atoms/Icons/Custom/Closes";

function TopBar({ location }) {
  const history = useHistory();

  return (
    <div className="fixed justify-between items-center" >
      <div className="flex pr-5 self-center">
        <div
          className="btn-icons text-gray-03 hover:text-gray-02 active:text-gray-01 bg-gray-05 p-3 rounded-lg cursor-pointer"
          onClick={() => history.goBack()}
        >
          <IconClose fill={"currentColor"} />
        </div>
        <p className="title ml-4">{location}</p>
      </div>

      <div/>

      <div className="flex self-center justify-self-end">
        <div className="mr-2">
          <Button color="blue" variant="secondary" >
            Selesai dan tambah lagi
          </Button>
        </div>
        <Button
          color="blue"
          variant="primary"
        >
          Selesai tambah karyawan
        </Button>
      </div>
    </div>
  );
}

export default TopBar;
