import { START_FETCHING, ERROR_FETCHING, SUCCESS_FETCHING } from "./constant";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",

};

const initialState = {
  token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MDdmYzUxYTRmOWUxMzYxYjhhNWNmMTEiLCJpYXQiOjE2MTk0OTYzMjZ9.2q_ww-JQ9E81yf1scpjE378jFGrhFGD91_4ZSya6J1s",
  access: [],
  status: statuslist.idle,
  dataLogin: {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING:
      return {
        ...state,
        token: action.token,
        access: action.access,
        status: statuslist.success,
      };
    case "LOGIN_SUCCESS" :
      return {
        ...state,
        dataLogin: action.payload
      }
    default:
      return state;
  }
}
