import * as React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import routes from "routes";
import store from "app/store";
import { Provider } from "react-redux";
import 'assets/stylesheets/button.css'
import 'assets/stylesheets/input.css'
import Spinner from "components/atoms/Spinner/SpinnerTable";

const Login = React.lazy(() => import("pages/Authentication/index"));
const Welcome = React.lazy(() => import("pages/Authentication/Onboard/index"));
const InfoAccount = React.lazy(() => import("pages/Authentication/Onboard/InfoAccount"));
const InfoBisnis = React.lazy(() => import("pages/Authentication/Onboard/InfoBisnis"));
const CreatePin = React.lazy(() => import("pages/Authentication/Onboard/CreatePin"));
const AuthComponent = React.lazy(() => import("routes/AuthComponent"));
const RegisterEmployeeQR = React.lazy(() => import("pages/QRCode/RegisterQR/Employee"))
const RegisterVisitorQR = React.lazy(() => import("pages/QRCode/RegisterQR/Visitor"));
const NotFound = React.lazy(() => import("components/templates/NotFound"));

const App = () => {

  return (
    <Provider store={store}>
      <React.Suspense fallback={<Spinner />}>
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/welcome" component={Welcome} />
            <Route exact path="/infomasi akun" component={InfoAccount} />
            <Route exact path="/infomasi bisnis" component={InfoBisnis} />
            <Route exact path="/buat pin" component={CreatePin} />
            <Route exact path="/qr-code-register/employee/:id" component={RegisterEmployeeQR} />
            <Route exact path="/qr-code-register/visitor/:id" component={RegisterVisitorQR} />
            {routes.map((route, i) => (
              <AuthComponent {...route} key={i} />
            ))}
            <Route component={NotFound} />
          </Switch>
        </Router>
      </React.Suspense>
    </Provider>
  );
};
export default App;