const menus = [
  {
    id: 10,
    check: "Dashbaord",
    name: "Dashboard",
    isDropDown: false,
    link: "/dashboard",
    icon: "UilEstate",
    prefix: "Dashboard",
  },
  {
    id: 6,
    name: "Karyawan",
    icon: "UilUserCircle",
    submenu: [
      {
        id: 1,
        name: "Daftar karyawan",
        link: "/daftar karyawan",
      },
      {
        id: 4,
        name: "Shift kerja",
        link: "/shift kerja",
      },
      {
        id: 5,
        name: "Departemen",
        link: "/departemen",
      },
      {
        id: 12,
        name: "Group data karyawan",
        link: "/group data karyawan",
      },
      {
        id: 17,
        name: "Penjadwalan",
        link: "/penjadwalan",
      },
    ],
  },
  {
    id: 20,
    name: "Pengunjung",
    icon: "UilBriefcase",
    submenu: [
      {
        id: 2,
        name: "Daftar pengunjung",
        link: "/daftar pengunjung",
      },
      {
        id: 3,
        name: "Kategori pengunjung",
        link: "/kategori pengunjung",
      },
      {
        id: 4,
        name: "Jam akses pengunjung",
        link: "/jam akses pengunjung",
      },
    ],
  },
  // {
  //   id: 13,
  //   name: "Kode QR",
  //   icon: "UilQrcodeScan",
  //   submenu: [
  //     {
  //       id: 11,
  //       name: "Daftar kode QR",
  //       link: "/daftar kode qr",
  //     },
  //     {
  //       id: 22,
  //       name: "Pendaftaran identitas",
  //       link: "/pendaftaran identitas",
  //     },
  //   ],
  // },
  {
    id: 15,
    name: "Perangkat",
    link: "/perangkat",
    icon: "UilDesktopAlt",
  },
  {
    id: 19,
    name: "Sejarah keluar-masuk",
    // link: "/sejarah keluar-masuk",
    icon: "UilFileCopyAlt",
    submenu: [
      {
        id: 27,
        name: "Karyawan",
        link: "/sejarah keluar-masuk/karyawan",
      },
      {
        id: 30,
        name: "Pengunjung",
        link: "/sejarah keluar-masuk/pengunjung",
      }
    ],
  },
  {
    id: 23,
    name: "Absensi",
    link: "/absensi",
    icon: "UilCalender",
  },
  { 
    id: 19,
    name: "Pengaturan",
    link: "/pengaturan",
    icon: "UilSetting",
  },
];

export default menus;
