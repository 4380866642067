import React, { useState } from "react";
import { useSelector, dispatch, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Button from "components/atoms/Button";
import Dropdown from "assets/icons-static/dropdown.svg";
import Dropup from "assets/icons-static/dropup.svg";
import "./style.css";

import {
  handleTabMenuIdentitas,
  handleTabMenuVisitor,
  handleTabMenuQR,
  handleTabMenuLog,
} from "features/Other/actions";

function Tab({ children }) {
  const dispatch = useDispatch();
  const location = useLocation().pathname;
  const [expand, setExpand] = useState(true);
  const { showIdentitas, showVisitor, showQR, showLog } = useSelector((state) => state.other);

  const handleShow = (type) => {
    setExpand(true);
    if (type === "Karyawan") {
      dispatch(handleTabMenuIdentitas(!showIdentitas));
      dispatch(handleTabMenuVisitor(false));
      dispatch(handleTabMenuQR(false));
      dispatch(handleTabMenuLog(false));
    }
    if (type === "Kode QR") {
      dispatch(handleTabMenuIdentitas(false));
      dispatch(handleTabMenuVisitor(false));
      dispatch(handleTabMenuQR(!showQR));
      dispatch(handleTabMenuLog(false));
    }
    if (type === "Pengunjung") {
      dispatch(handleTabMenuIdentitas(false));
      dispatch(handleTabMenuVisitor(!showVisitor));
      dispatch(handleTabMenuQR(false));
      dispatch(handleTabMenuLog(false));
    }
    if (type === "Sejarah keluar-masuk") {
      dispatch(handleTabMenuIdentitas(false));
      dispatch(handleTabMenuVisitor(false));
      dispatch(handleTabMenuQR(false));
      dispatch(handleTabMenuLog(!showLog));
    }
  };

  return (
    <div className="tab-container h-screen overflow-y-auto">
      {children.map((menu) => {
        return (
          <div key={menu.id}>
            <ul>
              <Button
                variant={menu.link === location ? "secondary" : "link"}
                color="blue"
                type={menu?.submenu ? "btnMenu" : "menu"}
                href={menu.link}
                onClick={() => (menu?.submenu ? handleShow(menu.name) : "")}
                isBlock={menu.link === location ? true : false}
                className={
                  menu.link === location
                    ? "flex my-2 focus:outline-none "
                    : "flex my-2 text-gray-03 items-center focus:outline-none"
                }
                content="icon infront"
                iconName={menu.icon}
              >
                {menu.name}
                {menu.name === "Karyawan" && (
                  <img src={expand && showIdentitas ? Dropdown : Dropup} className="ml-1" alt="" />
                )}
                {menu.name === "Kode QR" && (
                  <img src={expand && showQR ? Dropdown : Dropup} className="ml-1" alt="" />
                )}
                {menu.name === "Pengunjung" && (
                  <img src={expand && showVisitor ? Dropdown : Dropup} className="ml-1" alt="" />
                )}
                {menu.name === "Sejarah keluar-masuk" && <img src={ expand && showLog ? Dropdown : Dropup} className="ml-1" alt="" />}
              </Button>
            </ul>

            {showIdentitas && menu.name === "Karyawan" && (
              <ul>
                {expand
                  ? menu?.submenu?.map((submenu) => {
                      return (
                        <Button
                          key={submenu.id}
                          variant={submenu.link === location ? "secondary" : "link"}
                          color="blue"
                          type={"link"}
                          href={submenu.link}
                          isBlock={submenu.link ? true : false}
                          isSubmenu={true}
                          className={
                            submenu.link === location
                              ? " flex my-2 pl-10 "
                              : " flex my-2 pl-10 text-gray-03 "
                          }
                        >
                          {submenu.name}
                        </Button>
                      );
                    })
                  : ""}
              </ul>
            )}

            {showVisitor && menu.name === "Pengunjung" && (
              <ul>
                {expand
                  ? menu?.submenu?.map((submenu) => {
                      return (
                        <Button
                          key={submenu.id}
                          variant={submenu.link === location ? "secondary" : "link"}
                          color="blue"
                          type={"link"}
                          href={submenu.link}
                          isBlock={submenu.link ? true : false}
                          isSubmenu={true}
                          className={
                            submenu.link === location
                              ? " flex my-2 pl-10 "
                              : " flex my-2 pl-10 text-gray-03 "
                          }
                        >
                          {submenu.name}
                        </Button>
                      );
                    })
                  : ""}
              </ul>
            )}

            {showQR && menu.name === "Kode QR" && (
              <ul>
                {expand
                  ? menu?.submenu?.map((submenu) => {
                      return (
                        <Button
                          key={submenu.id}
                          variant={submenu.link === location ? "secondary" : "link"}
                          color="blue"
                          type={"link"}
                          href={submenu.link}
                          isBlock={submenu.link ? true : false}
                          isSubmenu={true}
                          className={
                            submenu.link === location
                              ? " flex my-2 pl-10 "
                              : " flex my-2 pl-10 text-gray-03 "
                          }
                        >
                          {submenu.name}
                        </Button>
                      );
                    })
                  : ""}
              </ul>
            )}

            {showLog && menu.name === "Sejarah keluar-masuk" && (
              <ul>
                {expand
                  ? menu?.submenu?.map((submenu) => {
                      return (
                        <Button
                          key={submenu.id}
                          variant={submenu.link === location ? "secondary" : "link"}
                          color="blue"
                          type={"link"}
                          href={submenu.link}
                          isBlock={submenu.link ? true : false}
                          isSubmenu={true}
                          className={
                            submenu.link === location
                              ? " flex my-2 pl-10 "
                              : " flex my-2 pl-10 text-gray-03 "
                          }
                        >
                          {submenu.name}
                        </Button>
                      );
                    })
                  : ""}
              </ul>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default Tab;
