import React from "react";
import Header from "./Header";
import ImgLoader from "assets/icons-static/load.svg"

export default function InfoAccount() {
  return (
    <div>
      <Header />

      <div
        style={{ paddingLeft: "20%", paddingRight: "20%" }}
        className="h-screen w-full flex flex-col justify-center items-center"
      >
        <img className="w-109 h-109" src={ImgLoader} alt="" />
        <div className="font-bold text-xl mt-4">Menyiapkan Visuo Anda...</div>
      </div>

    </div>
  );
}
