export const handleTabMenuIdentitas = (data) => {
    return {
      type: "SET_TAB_MENU_IDENTITAS",
      payload: data
    };
};

export const handleTabMenuVisitor = (data) => {
    return {
      type: "SET_TAB_MENU_VISITOR",
      payload: data
    };
};

export const handleTabMenuQR = (data) => {
    return {
      type: "SET_TAB_MENU_QR",
      payload: data
    };
};

export const handleTabMenuLog = (data) => {
  return {
    type: "SET_TAB_MENU_LOG",
    payload: data
  };
};

export const handleMenuSidebar = (data) => {
  return {
    type: "SET_MENU_SIDE_BAR",
    payload: data
  };
};