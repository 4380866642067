import React from "react";
import {useHistory} from "react-router-dom"
import "./index.css";
import Button from "components/atoms/Button";
import ImgArrow from "assets/icons-static/arrow_rigth_green.svg"

function Index({ setHide, position }) {
  const history = useHistory()
  return (
    <>
      {position === "" ? "" : <div onClick={setHide} className="overlay"></div>}
      <div className={`leftbar ${position}`}>
        <div className="leftbar-header ">
          <div className="flex items-center pr-5 self-center">
            <Button
              variant="secondary"
              color="gray"
              content="label only"
              onClick={setHide}
            >
              X
            </Button>
            <div className="font-semibold text-gray-04 text-base ml-4">Panduan visuo</div>
          </div>
        </div>

        <div className="mt-4 h-5/6 p-4">
          
          <div className="rounded-md cursor-pointer flex justify-between items-center bg-gray-100 p-4 mb-2 mt-2"> 
            <div>
              <div className="font-semibold text-sm">Tambah shift kerja</div>
              <p className="text-xs w-10/12">Tambahkan shift kerja untuk menentukan waktu yang karyawan harus masuk dan penuhi jam kerjanya.</p>
            </div>
            <div><img className="w-4 h-4" src={ImgArrow} alt="" /></div> 
          </div>
          <div className="rounded-md cursor-pointer flex justify-between items-center bg-gray-100 p-4 mb-2">
            <div>
              <div className="font-semibold text-sm">Tambah departemen</div>
              <p className="text-xs w-10/12">Tambahkan departemen untuk mengkategorikan karyawan dan pelajari kinerja masing-masing departemen.</p>
            
            </div>
            <div><img className="w-4 h-4" src={ImgArrow} alt="" /></div>
          </div>
          <div onClick={() => history.push("/tambah karyawan")} className="rounded-md cursor-pointer flex justify-between items-center bg-gray-100 p-4 mb-2">
            <div>
              <div className="font-semibold text-sm">Tambah karyawan</div>
              <p className="text-xs w-10/12">Tambahkan atau impor daftar karyawan Anda untuk dilacak dan dihitung jam kerja yang harus dipenuhi.</p>
            
            </div>
            <div><img className="w-4 h-4" src={ImgArrow} alt="" /></div>
          </div>
          <div className="rounded-md cursor-pointer flex justify-between items-center bg-gray-100 p-4 mb-2">
            <div>
              <div className="font-semibold text-sm">Beri akses akun</div>
              <p className="text-xs w-10/12">Berikan akses akun kepada atasan lainnya untuk manajemen absensi karyawan dan tugas lainnya.</p>
            </div>
            <div><img className="w-4 h-4" src={ImgArrow} alt="" /></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
