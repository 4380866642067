const initialState = {
    showIdentitas: false,
    showVisitor: false,
    showQR: false,
    showLog: false,
    showMenuSidebar : true
};
  
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "SET_TAB_MENU_IDENTITAS":
            return { ...state, showIdentitas : action.payload };
        case "SET_TAB_MENU_VISITOR":
            return { ...state, showVisitor : action.payload };
        case "SET_TAB_MENU_QR":
            return { ...state, showQR : action.payload };
        case "SET_TAB_MENU_LOG":
            return { ...state, showLog : action.payload };
        case "SET_MENU_SIDE_BAR":
            return { ...state, showMenuSidebar : action.payload };
    default:
        return state;
    }
}

