const initialState = {
  typeAlert: null,
  name: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_TYPE_ALERT":
      return { ...state, typeAlert: action.payload };
    case "SET_NAME":
      return { ...state, name: action.payload };

    default:
      return state;
  }
}
