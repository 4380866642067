import * as React from "react";
import * as Icon from "react-icons/fa";
// import * as Icon from 'react-icons/bi'

const Icons = (props) => {
  const { iconName, iconSize = "", iconColor = "", className = "" } = props;
  const icon = React.createElement(Icon[iconName]);
  return (
    <div style={{ fontSize: iconSize, color: iconColor }} className={className}>
      {icon}
    </div>
  );
};

export default Icons;
