import React from "react";
import propTypes from "prop-types";
import { ButtonSizes } from "./ButtonType";
import { Link } from "react-router-dom";
import { CgSpinnerAlt } from "react-icons/cg";
import Icon from "components/atoms/Icons";
import Icons from "components/atoms/Icons/indexV2";
import IconMenu from "components/atoms/Icons/iconMenu";
/*
 * notes button :
 * variant = primary, secondary, outlined, link
 * color = blue, gray, green, red
 * content = label only, icon infront, icon behind, icon only
 * disabled
 * loading
 */
const Button = (props) => {
  const {
    isBlock,
    variant,
    href,
    disabled,
    target,
    children,
    external,
    type,
    size,
    loading,
    content,
    color,
    iconColor,
    iconSize,
    iconName,
    isSubmenu,
    onClick,
    form,
  } = props;

  const className = [props.className];
  if (isBlock) className.push("w-full");
  if (size) className.push("");
  // START:BUTTON BLUE
  // button variant=primary, color=blue, content=label only and icont infront
  if (
    variant === "primary" &&
    color === "blue" &&
    content === "label only" &&
    disabled === false &&
    loading === false
  )
    className.push("btn-primary bg-blue-03 hover:bg-blue-02 active:bg-blue-01");
  if (
    variant === "primary" &&
    color === "blue" &&
    content === "label only" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-primary bg-blue-04 cursor-not-allowed");
  if (variant === "primary" && color === "blue" && loading === true)
    className.push("btn-primary bg-blue-04 flex items-center justify-center cursor-not-allowed");
  // button variant=secondary, color=blue, content=label only and icont infront
  if (
    variant === "secondary" &&
    color === "blue" &&
    content === "label only" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-secondary text-blue-03 hover:text-blue-02 relative active:text-blue-01 bg-blue-05"
    );
  if (
    variant === "secondary" &&
    color === "blue" &&
    content === "label only" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-secondary text-blue-04 bg-blue-05 cursor-not-allowed");
  if (variant === "secondary" && color === "blue" && loading === true)
    className.push("btn-secondary text-blue-04 bg-blue-05 flex items-center cursor-not-allowed");
  // button variant=outlined, color=blue, content=label only and icont infront
  if (
    variant === "outlined" &&
    color === "blue" &&
    content === "label only" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-outlined text-blue-03 border-blue-03 hover:border-blue-02 hover:text-blue-02 active:text-blue-01 active:border-blue-01"
    );
  if (
    variant === "outlined" &&
    color === "blue" &&
    content === "label only" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-outlined text-blue-04 border-blue-04 cursor-not-allowed");
  if (variant === "outlined" && color === "blue" && loading === true)
    className.push("btn-outlined text-blue-04 border-blue-04 flex items-center cursor-not-allowed");
  // button variant=link, color=blue, content=label only and icont infront
  if (
    variant === "link" &&
    color === "blue" &&
    content === "label only" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-link text-blue-03 outline-none focus:outline-none hover:text-blue-02 active:text-blue-01"
    );
  if (
    variant === "link" &&
    color === "blue" &&
    content === "label only" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-link text-blue-04 cursor-not-allowed");
  if (variant === "link" && color === "blue" && loading === true)
    className.push("btn-link text-blue-04 flex items-center cursor-not-allowed");
  // button variant=primary, color=blue, content=label only and icont infront
  if (
    variant === "primary" &&
    color === "blue" &&
    content === "icon infront" &&
    disabled === false &&
    loading === false
  )
    className.push("btn-primary bg-blue-03 hover:bg-blue-02 active:bg-blue-01 flex items-center");
  if (
    variant === "primary" &&
    color === "blue" &&
    content === "icon infront" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-primary bg-blue-04 flex items-center cursor-not-allowed");
  if (variant === "primary" && color === "blue" && content === "icon infront" && loading === true)
    className.push("btn-primary bg-blue-04 flex items-center");
  // button variant=secondary, color=blue, content=label only and icont infront
  if (
    variant === "secondary" &&
    color === "blue" &&
    content === "icon infront" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-secondary text-blue-03 hover:text-blue-02 active:text-blue-01 bg-blue-05 flex items-center"
    );
  if (
    variant === "secondary" &&
    color === "blue" &&
    content === "icon infront" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-secondary text-blue-04 bg-blue-05 flex items-center cursor-not-allowed");
  if (variant === "secondary" && color === "blue" && content === "icon infront" && loading === true)
    className.push("btn-secondary text-blue-04 bg-blue-05 flex items-center");
  // button variant=outlined, color=blue, content=label only and icont infront
  if (
    variant === "outlined" &&
    color === "blue" &&
    content === "icon infront" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-outlined text-blue-03 border-blue-03 hover:border-blue-02 hover:text-blue-02 active:text-blue-01 active:border-blue-01 flex items-center"
    );
  if (
    variant === "outlined" &&
    color === "blue" &&
    content === "icon infront" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-outlined text-blue-04 border-blue-03 flex items-center cursor-not-allowed");
  if (variant === "outlined" && color === "blue" && content === "icon infront" && loading === true)
    className.push("btn-outlined text-blue-04 border-blue-03 flex items-center");
  // button variant = link, color = blue, content = label only and icont infront
  if (
    variant === "link" &&
    color === "blue" &&
    content === "icon infront" &&
    disabled === false &&
    loading === false
  )
    className.push("btn-link text-blue-03 hover:text-blue-02 flex items-center");
  if (
    variant === "link" &&
    color === "blue" &&
    content === "icon infront" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-link text-blue-04 cursor-not-allowed flex items-center");
  if (variant === "link" && color === "blue" && content === "icon infront" && loading === true)
    className.push("btn-link text-blue-04 flex items-center cursor-not-allowed flex items-center");
  // button variant = primary, color = blue, content = label only and icont behind
  if (
    variant === "primary" &&
    color === "blue" &&
    content === "icon behind" &&
    disabled === false &&
    loading === false
  )
    className.push("btn-primary bg-blue-03 hover:bg-blue-02 active:bg-blue-01 flex items-center");
  if (
    variant === "primary" &&
    color === "blue" &&
    content === "icon behind" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-primary bg-blue-04 flex items-center cursor-not-allowed");
  if (variant === "primary" && color === "blue" && content === "icon behind" && loading === true)
    className.push("btn-primary bg-blue-04 flex items-center");
  // button variant = secondary, color = blue, content = label only and icont behind
  if (
    variant === "secondary" &&
    color === "blue" &&
    content === "icon behind" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-secondary text-blue-03 hover:text-blue-02 active:text-blue-01 bg-blue-05 flex items-center"
    );
  if (
    variant === "secondary" &&
    color === "blue" &&
    content === "icon behind" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-secondary text-blue-04 bg-blue-05 flex items-center cursor-not-allowed");
  if (variant === "secondary" && color === "blue" && content === "icon behind" && loading === true)
    className.push("btn-secondary text-blue-04 bg-blue-05 flex items-center");
  // button variant = outlined, color = blue, content = label only and icont behind
  if (
    variant === "outlined" &&
    color === "blue" &&
    content === "icon behind" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-outlined text-blue-03 hover:text-blue-02 hover:border-blue-02 active:border-blue-01 active:text-blue-01 border-blue-03 bg-white flex items-center"
    );
  if (
    variant === "outlined" &&
    color === "blue" &&
    content === "icon behind" &&
    disabled === true &&
    loading === false
  )
    className.push(
      "btn-outlined text-blue-04 border-blue-03 border bg-white flex items-center cursor-not-allowed"
    );
  if (variant === "outlined" && color === "blue" && content === "icon behind" && loading === true)
    className.push("btn-outlined text-blue-04 border-blue-03 flex items-center");
  // button variant = outlined, color = blue, content = label only and icont behind
  if (
    variant === "link" &&
    color === "blue" &&
    content === "icon behind" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-link text-blue-03 hover:text-blue-02 active:text-blue-01 flex items-center"
    );
  if (
    variant === "link" &&
    color === "blue" &&
    content === "icon behind" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-link text-blue-04 cursor-not-allowed flex items-center");
  if (variant === "link" && color === "blue" && content === "icon behind" && loading === true)
    className.push("btn-link text-blue-04 flex items-center cursor-not-allowed flex items-center");
  // button variant = primary, color = blue, content = icon only
  if (
    variant === "primary" &&
    color === "blue" &&
    content === "icon only" &&
    loading === false &&
    disabled === false
  )
    className.push("btn-primary p-3 bg-blue-03 hover:bg-blue-02 active:bg-blue-01");
  if (variant === "primary" && color === "blue" && content === "icon only" && disabled === true)
    className.push("btn-primary p-3 bg-blue-04 cursor-not-allowed");
  if (variant === "primary" && color === "blue" && content === "icon only" && loading === true)
    className.push("btn-primary p-3 bg-blue-04 cursor-not-allowed");
  // button variant = secondary, color = blue, content = icon only
  if (
    variant === "secondary" &&
    color === "blue" &&
    content === "icon only" &&
    loading === false &&
    disabled === false
  )
    className.push(
      "btn-secondary p-3 bg-blue-05 text-blue-03 hover:text-blue-02 active:text-blue-01"
    );
  if (variant === "secondary" && color === "blue" && content === "icon only" && disabled === true) {
    className.push("btn-secondary p-3 text-blue-04 bg-blue-05 cursor-not-allowed");
    console.log("masuk");
  }
  if (variant === "secondary" && color === "blue" && content === "icon only" && loading === true)
    className.push("btn-secondary p-3 text-blue-04 bg-blue-05 cursor-not-allowed");
  // button variant = outlined, color = blue, content = icon only
  if (
    variant === "outlined" &&
    color === "blue" &&
    content === "icon only" &&
    loading === false &&
    disabled === false
  )
    className.push(
      "btn-outlined p-3 text-blue-03 border-blue-03 hover:text-blue-02 active:text-blue-01  hover:border-blue-02 active:border-blue-01"
    );
  if (variant === "outlined" && color === "blue" && content === "icon only" && disabled === true)
    className.push("btn-outlined p-3 text-blue-04 border-blue-04 cursor-not-allowed");
  if (variant === "outlined" && color === "blue" && content === "icon only" && loading === true)
    className.push("btn-outlined p-3 text-blue-04 border-blue-04 cursor-not-allowed");
  // button variant = link, color = blue, content = icon only
  if (
    variant === "link" &&
    color === "blue" &&
    content === "icon only" &&
    loading === false &&
    disabled === false
  )
    className.push(
      "btn-link p-3 bg-transparent text-blue-03 hover:text-blue-02 active:text-blue-01 duration-500 hover:border-blue-02 active:border-blue-01 focus:outline-none outline-none"
    );
  if (variant === "link" && color === "blue" && content === "icon only" && disabled === true)
    className.push(
      "btn-link p-3 rounded-lg cursor-pointer bg-transparent text-blue-04 outline-none cursor-not-allowed"
    );
  if (variant === "link" && color === "blue" && content === "icon only" && loading === true)
    className.push(
      "btn-link p-3 rounded-lg cursor-pointer bg-transparent text-blue-04  outline-none cursor-not-allowed"
    );
  // ============================== END:BUTTON BLUE =========================== \\
  // ============================== START:BUTTON GRAY =========================== \\
  // button variant=primary, color=gray, content=label only and icont infront
  if (
    variant === "primary" &&
    color === "gray" &&
    content === "label only" &&
    disabled === false &&
    loading === false
  )
    className.push("btn-primary bg-gray-03 hover:bg-gray-02  active:bg-gray-01");
  if (
    variant === "primary" &&
    color === "gray" &&
    content === "label only" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-primary bg-gray-04 cursor-not-allowed");
  if (variant === "primary" && color === "gray" && loading === true)
    className.push("btn-primary bg-gray-04 flex items-center cursor-not-allowed");

  // button variant=secondary, color=gray, content=label only and icont infront
  if (
    variant === "secondary" &&
    color === "gray" &&
    content === "label only" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-secondary text-gray-03 hover:text-gray-02 relative active:text-gray-01 bg-gray-05"
    );
  if (
    variant === "secondary" &&
    color === "gray" &&
    content === "label only" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-secondary text-gray-04 bg-gray-05 cursor-not-allowed");
  if (variant === "secondary" && color === "gray" && loading === true)
    className.push("btn-secondary text-gray-04 bg-gray-05 flex items-center cursor-not-allowed");
  // button variant=outlined, color=gray, content=label only and icont infront
  if (
    variant === "outlined" &&
    color === "gray" &&
    content === "label only" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-outlined text-gray-03 border-gray-03 hover:border-gray-02 hover:text-gray-02 active:text-gray-01 active:border-gray-01"
    );
  if (
    variant === "outlined" &&
    color === "gray" &&
    content === "label only" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-outlined text-gray-04 border-gray-04 cursor-not-allowed");
  if (variant === "outlined" && color === "gray" && loading === true)
    className.push("btn-outlined text-gray-04 border-gray-04 flex items-center cursor-not-allowed");
  // button variant=link, color=gray, content=label only and icont infront
  if (
    variant === "link" &&
    color === "gray" &&
    content === "label only" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-link text-gray-03 outline-none focus:outline-none hover:text-gray-02 active:text-gray-01"
    );
  if (
    variant === "link" &&
    color === "gray" &&
    content === "label only" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-link text-gray-04 cursor-not-allowed");
  if (variant === "link" && color === "gray" && loading === true)
    className.push("btn-link text-gray-04 flex items-center cursor-not-allowed");
  // button variant=primary, color=gray, content=label only and icont infront
  if (
    variant === "primary" &&
    color === "gray" &&
    content === "icon infront" &&
    disabled === false &&
    loading === false
  )
    className.push("btn-primary bg-gray-03 hover:bg-gray-02 active:bg-gray-01 flex items-center");
  if (
    variant === "primary" &&
    color === "gray" &&
    content === "icon infront" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-primary bg-gray-04 flex items-center cursor-not-allowed");
  if (variant === "primary" && color === "gray" && content === "icon infront" && loading === true)
    className.push("btn-primary bg-gray-04 flex items-center");
  // button variant=secondary, color=gray, content=label only and icont infront
  if (
    variant === "secondary" &&
    color === "gray" &&
    content === "icon infront" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-secondary text-gray-03 hover:text-gray-02 active:text-gray-01 bg-gray-05 flex items-center"
    );
  if (
    variant === "secondary" &&
    color === "gray" &&
    content === "icon infront" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-secondary text-gray-04 bg-gray-05 flex items-center cursor-not-allowed");
  if (variant === "secondary" && color === "gray" && content === "icon infront" && loading === true)
    className.push("btn-secondary text-gray-04 bg-gray-05 flex items-center");
  // button variant=outlined, color=gray, content=label only and icont infront
  if (
    variant === "outlined" &&
    color === "gray" &&
    content === "icon infront" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-outlined text-gray-03 border-gray-03 hover:border-gray-02 hover:text-gray-02 active:text-gray-01 active:border-gray-01 flex items-center"
    );
  if (
    variant === "outlined" &&
    color === "gray" &&
    content === "icon infront" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-outlined text-gray-04 border-gray-03 flex items-center cursor-not-allowed");
  if (variant === "outlined" && color === "gray" && content === "icon infront" && loading === true)
    className.push("btn-outlined text-gray-04 border-gray-03 flex items-center");
  // button variant = link, color = gray, content = label only and icont infront
  if (
    variant === "link" &&
    color === "gray" &&
    content === "icon infront" &&
    disabled === false &&
    loading === false
  )
    className.push("btn-link text-gray-03 hover:text-gray-02 flex items-center");
  if (
    variant === "link" &&
    color === "gray" &&
    content === "icon infront" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-link text-gray-04 cursor-not-allowed flex items-center");
  if (variant === "link" && color === "gray" && content === "icon infront" && loading === true)
    className.push("btn-link text-gray-04 flex items-center cursor-not-allowed flex items-center");
  // button variant = primary, color = gray, content = label only and icont behind
  if (
    variant === "primary" &&
    color === "gray" &&
    content === "icon behind" &&
    disabled === false &&
    loading === false
  )
    className.push("btn-primary bg-gray-03 hover:bg-gray-02 active:bg-gray-01 flex items-center");
  if (
    variant === "primary" &&
    color === "gray" &&
    content === "icon behind" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-primary bg-gray-04 flex items-center cursor-not-allowed");
  if (variant === "primary" && color === "gray" && content === "icon behind" && loading === true)
    className.push("btn-primary bg-gray-04 flex items-center");
  // button variant = secondary, color = gray, content = label only and icont behind
  if (
    variant === "secondary" &&
    color === "gray" &&
    content === "icon behind" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-secondary text-gray-03 hover:text-gray-02 active:text-gray-01 bg-gray-05 flex items-center"
    );
  if (
    variant === "secondary" &&
    color === "gray" &&
    content === "icon behind" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-secondary text-gray-04 bg-gray-05 flex items-center cursor-not-allowed");
  if (variant === "secondary" && color === "gray" && content === "icon behind" && loading === true)
    className.push("btn-secondary text-gray-04 bg-gray-05 flex items-center");
  // button variant = outlined, color = gray, content = label only and icont behind
  if (
    variant === "outlined" &&
    color === "gray" &&
    content === "icon behind" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-outlined text-gray-03 hover:text-gray-02 hover:border-gray-02 active:border-gray-01 active:text-gray-01 border-gray-03 bg-white flex items-center"
    );
  if (
    variant === "outlined" &&
    color === "gray" &&
    content === "icon behind" &&
    disabled === true &&
    loading === false
  )
    className.push(
      "btn-outlined text-gray-04 border-gray-03 border bg-white flex items-center cursor-not-allowed"
    );
  if (variant === "outlined" && color === "gray" && content === "icon behind" && loading === true)
    className.push("btn-outlined text-gray-04 border-gray-03 flex items-center");
  // button variant = outlined, color = gray, content = label only and icont behind
  if (
    variant === "link" &&
    color === "gray" &&
    content === "icon behind" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-link text-gray-03 hover:text-gray-02 active:text-gray-01 flex items-center"
    );
  if (
    variant === "link" &&
    color === "gray" &&
    content === "icon behind" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-link text-gray-04 cursor-not-allowed flex items-center");
  if (variant === "link" && color === "gray" && content === "icon behind" && loading === true)
    className.push("btn-link text-gray-04 flex items-center cursor-not-allowed flex items-center");
  // button variant = primary, color = gray, content = icon only
  if (
    variant === "primary" &&
    color === "gray" &&
    content === "icon only" &&
    loading === false &&
    disabled === false
  )
    className.push("btn-primary p-3 bg-gray-03 hover:bg-gray-02 active:bg-gray-01");
  if (variant === "primary" && color === "gray" && content === "icon only" && disabled === true)
    className.push("btn-primary p-3 bg-gray-04 cursor-not-allowed");
  if (variant === "primary" && color === "gray" && content === "icon only" && loading === true)
    className.push("btn-primary p-3 bg-gray-04 cursor-not-allowed");
  // button variant = secondary, color = gray, content = icon only
  if (
    variant === "secondary" &&
    color === "gray" &&
    content === "icon only" &&
    loading === false &&
    disabled === false
  )
    className.push(
      "btn-secondary p-3 bg-gray-05 text-gray-03 hover:text-gray-02 active:text-gray-01"
    );
  if (variant === "secondary" && color === "gray" && content === "icon only" && disabled === true) {
    className.push("btn-secondary p-3 text-gray-04 bg-gray-05 cursor-not-allowed");
    console.log("masuk");
  }
  if (variant === "secondary" && color === "gray" && content === "icon only" && loading === true)
    className.push("btn-secondary p-3 text-gray-04 bg-gray-05 cursor-not-allowed");
  // button variant = outlined, color = gray, content = icon only
  if (
    variant === "outlined" &&
    color === "gray" &&
    content === "icon only" &&
    loading === false &&
    disabled === false
  )
    className.push(
      "btn-outlined p-3 text-gray-03 border-gray-03 hover:text-gray-02 active:text-gray-01  hover:border-gray-02 active:border-gray-01"
    );
  if (variant === "outlined" && color === "gray" && content === "icon only" && disabled === true)
    className.push("btn-outlined p-3 text-gray-04 border-gray-04 cursor-not-allowed");
  if (variant === "outlined" && color === "gray" && content === "icon only" && loading === true)
    className.push("btn-outlined p-3 text-gray-04 border-gray-04 cursor-not-allowed");
  // button variant = link, color = gray, content = icon only
  if (
    variant === "link" &&
    color === "gray" &&
    content === "icon only" &&
    loading === false &&
    disabled === false
  )
    className.push(
      "btn-link p-3 bg-white text-gray-03 hover:text-gray-02 active:text-gray-01 duration-500 hover:border-gray-02 active:border-gray-01 focus:outline-none outline-none"
    );
  if (variant === "link" && color === "gray" && content === "icon only" && disabled === true)
    className.push(
      "btn-link p-3 rounded-lg cursor-pointer bg-white text-gray-04 outline-none cursor-not-allowed"
    );
  if (variant === "link" && color === "gray" && content === "icon only" && loading === true)
    className.push(
      "btn-link p-3 rounded-lg cursor-pointer bg-white text-gray-04  outline-none cursor-not-allowed"
    );

  // ============================== END:BUTTON GRAY =========================== \\
  // ============================== START:BUTTON GREEN =========================== \\
  // button variant=secondary, color=green, content=label only and icont infront
  if (
    variant === "primary" &&
    color === "green" &&
    content === "label only" &&
    disabled === false &&
    loading === false
  )
    className.push("btn-primary bg-green-03 hover:bg-green-02  active:bg-green-01");
  if (
    variant === "primary" &&
    color === "green" &&
    content === "label only" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-primary bg-green-04 cursor-not-allowed");
  if (variant === "primary" && color === "green" && loading === true)
    className.push("btn-primary bg-green-04 flex items-center cursor-not-allowed");

  // button variant=secondary, color=green, content=label only and icont infront
  if (
    variant === "secondary" &&
    color === "green" &&
    content === "label only" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-secondary text-green-03 hover:text-green-02 relative active:text-green-01 bg-green-05"
    );
  if (
    variant === "secondary" &&
    color === "green" &&
    content === "label only" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-secondary text-green-04 bg-green-05 cursor-not-allowed");
  if (variant === "secondary" && color === "green" && loading === true)
    className.push("btn-secondary text-green-04 bg-green-05 flex items-center cursor-not-allowed");
  // button variant=outlined, color=green, content=label only and icont infront
  if (
    variant === "outlined" &&
    color === "green" &&
    content === "label only" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-outlined text-green-03 border-green-03 hover:border-green-02 hover:text-green-02 active:text-green-01 active:border-green-01"
    );
  if (
    variant === "outlined" &&
    color === "green" &&
    content === "label only" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-outlined text-green-04 border-green-04 cursor-not-allowed");
  if (variant === "outlined" && color === "green" && loading === true)
    className.push(
      "btn-outlined text-green-04 border-green-04 flex items-center cursor-not-allowed"
    );
  // button variant=link, color=green, content=label only and icont infront
  if (
    variant === "link" &&
    color === "green" &&
    content === "label only" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-link text-green-03 outline-none focus:outline-none hover:text-green-02 active:text-green-01"
    );
  if (
    variant === "link" &&
    color === "green" &&
    content === "label only" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-link text-green-04 cursor-not-allowed");
  if (variant === "link" && color === "green" && loading === true)
    className.push("btn-link text-green-04 flex items-center cursor-not-allowed");
  // button variant=primary, color=green, content=label only and icont infront
  if (
    variant === "primary" &&
    color === "green" &&
    content === "icon infront" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-primary bg-green-03 hover:bg-green-02 active:bg-green-01 flex items-center"
    );
  if (
    variant === "primary" &&
    color === "green" &&
    content === "icon infront" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-primary bg-green-04 flex items-center cursor-not-allowed");
  if (variant === "primary" && color === "green" && content === "icon infront" && loading === true)
    className.push("btn-primary bg-green-04 flex items-center");
  // button variant=secondary, color=green, content=label only and icont infront
  if (
    variant === "secondary" &&
    color === "green" &&
    content === "icon infront" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-secondary text-green-03 hover:text-green-02 active:text-green-01 bg-green-05 flex items-center"
    );
  if (
    variant === "secondary" &&
    color === "green" &&
    content === "icon infront" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-secondary text-green-04 bg-green-05 flex items-center cursor-not-allowed");
  if (
    variant === "secondary" &&
    color === "green" &&
    content === "icon infront" &&
    loading === true
  )
    className.push("btn-secondary text-green-04 bg-green-05 flex items-center");
  // button variant=outlined, color=green, content=label only and icont infront
  if (
    variant === "outlined" &&
    color === "green" &&
    content === "icon infront" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-outlined text-green-03 border-green-03 hover:border-green-02 hover:text-green-02 active:text-green-01 active:border-green-01 flex items-center"
    );
  if (
    variant === "outlined" &&
    color === "green" &&
    content === "icon infront" &&
    disabled === true &&
    loading === false
  )
    className.push(
      "btn-outlined text-green-04 border-green-03 flex items-center cursor-not-allowed"
    );
  if (variant === "outlined" && color === "green" && content === "icon infront" && loading === true)
    className.push("btn-outlined text-green-04 border-green-03 flex items-center");
  // button variant = link, color = green, content = label only and icont infront
  if (
    variant === "link" &&
    color === "green" &&
    content === "icon infront" &&
    disabled === false &&
    loading === false
  )
    className.push("btn-link text-green-03 hover:text-green-02 flex items-center");
  if (
    variant === "link" &&
    color === "green" &&
    content === "icon infront" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-link text-green-04 cursor-not-allowed flex items-center");
  if (variant === "link" && color === "green" && content === "icon infront" && loading === true)
    className.push("btn-link text-green-04 flex items-center cursor-not-allowed flex items-center");
  // button variant = primary, color = green, content = label only and icont behind
  if (
    variant === "primary" &&
    color === "green" &&
    content === "icon behind" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-primary bg-green-03 hover:bg-green-02 active:bg-green-01 flex items-center"
    );
  if (
    variant === "primary" &&
    color === "green" &&
    content === "icon behind" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-primary bg-green-04 flex items-center cursor-not-allowed");
  if (variant === "primary" && color === "green" && content === "icon behind" && loading === true)
    className.push("btn-primary bg-green-04 flex items-center");
  // button variant = secondary, color = green, content = label only and icont behind
  if (
    variant === "secondary" &&
    color === "green" &&
    content === "icon behind" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-secondary text-green-03 hover:text-green-02 active:text-green-01 bg-green-05 flex items-center"
    );
  if (
    variant === "secondary" &&
    color === "green" &&
    content === "icon behind" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-secondary text-green-04 bg-green-05 flex items-center cursor-not-allowed");
  if (variant === "secondary" && color === "green" && content === "icon behind" && loading === true)
    className.push("btn-secondary text-green-04 bg-green-05 flex items-center");
  // button variant = outlined, color = green, content = label only and icont behind
  if (
    variant === "outlined" &&
    color === "green" &&
    content === "icon behind" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-outlined text-green-03 hover:text-green-02 hover:border-green-02 active:border-green-01 active:text-green-01 border-green-03 bg-white flex items-center"
    );
  if (
    variant === "outlined" &&
    color === "green" &&
    content === "icon behind" &&
    disabled === true &&
    loading === false
  )
    className.push(
      "btn-outlined text-green-04 border-green-03 border bg-white flex items-center cursor-not-allowed"
    );
  if (variant === "outlined" && color === "green" && content === "icon behind" && loading === true)
    className.push("btn-outlined text-green-04 border-green-03 flex items-center");
  // button variant = outlined, color = green, content = label only and icont behind
  if (
    variant === "link" &&
    color === "green" &&
    content === "icon behind" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-link text-green-03 hover:text-green-02 active:text-green-01 flex items-center"
    );
  if (
    variant === "link" &&
    color === "green" &&
    content === "icon behind" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-link text-green-04 cursor-not-allowed flex items-center");
  // button variant = primary, color = green, content = icon only
  if (
    variant === "primary" &&
    color === "green" &&
    content === "icon only" &&
    loading === false &&
    disabled === false
  )
    className.push("btn-primary p-3 bg-green-03 hover:bg-green-02 active:bg-green-01");
  if (variant === "primary" && color === "green" && content === "icon only" && disabled === true)
    className.push("btn-primary p-3 bg-green-04 cursor-not-allowed");
  if (variant === "primary" && color === "green" && content === "icon only" && loading === true)
    className.push("btn-primary p-3 bg-green-04 cursor-not-allowed");
  // button variant = secondary, color = green, content = icon only
  if (
    variant === "secondary" &&
    color === "green" &&
    content === "icon only" &&
    loading === false &&
    disabled === false
  )
    className.push(
      "btn-secondary p-3 bg-green-05 text-green-03 hover:text-green-02 active:text-green-01"
    );
  if (
    variant === "secondary" &&
    color === "green" &&
    content === "icon only" &&
    disabled === true
  ) {
    className.push("btn-secondary p-3 text-green-04 bg-green-05 cursor-not-allowed");
    console.log("masuk");
  }
  if (variant === "secondary" && color === "green" && content === "icon only" && loading === true)
    className.push("btn-secondary p-3 text-green-04 bg-green-05 cursor-not-allowed");
  // button variant = outlined, color = green, content = icon only
  if (
    variant === "outlined" &&
    color === "green" &&
    content === "icon only" &&
    loading === false &&
    disabled === false
  )
    className.push(
      "btn-outlined p-3 text-green-03 border-green-03 hover:text-green-02 active:text-green-01  hover:border-green-02 active:border-green-01"
    );
  if (variant === "outlined" && color === "green" && content === "icon only" && disabled === true)
    className.push("btn-outlined p-3 text-green-04 border-green-04 cursor-not-allowed");
  if (variant === "outlined" && color === "green" && content === "icon only" && loading === true)
    className.push("btn-outlined p-3 text-green-04 border-green-04 cursor-not-allowed");
  // button variant = link, color = green, content = icon only
  if (
    variant === "link" &&
    color === "green" &&
    content === "icon only" &&
    loading === false &&
    disabled === false
  )
    className.push(
      "btn-link p-3 bg-white text-green-03 hover:text-green-02 active:text-green-01 duration-500 hover:border-green-02 active:border-green-01 focus:outline-none outline-none"
    );
  if (variant === "link" && color === "green" && content === "icon only" && disabled === true)
    className.push(
      "btn-link p-3 rounded-lg cursor-pointer bg-white text-green-04 outline-none cursor-not-allowed"
    );
  if (variant === "link" && color === "green" && content === "icon only" && loading === true)
    className.push(
      "btn-link p-3 rounded-lg cursor-pointer bg-white text-green-04  outline-none cursor-not-allowed"
    );
  // ============================== END:BUTTON GREEN =========================== \\
  // ============================== START:BUTTON RED =========================== \\
  // button variant=secondary, color=red, content=label only and icont infront
  if (
    variant === "primary" &&
    color === "red" &&
    content === "label only" &&
    disabled === false &&
    loading === false
  )
    className.push("btn-primary bg-red-03 hover:bg-red-02  active:bg-red-01");
  if (
    variant === "primary" &&
    color === "red" &&
    content === "label only" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-primary bg-red-04 cursor-not-allowed");
  if (variant === "primary" && color === "red" && loading === true)
    className.push("btn-primary bg-red-04 flex items-center cursor-not-allowed");

  // button variant=secondary, color=red, content=label only and icont infront
  if (
    variant === "secondary" &&
    color === "red" &&
    content === "label only" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-secondary text-red-03 hover:text-red-02 relative active:text-red-01 bg-red-05"
    );
  if (
    variant === "secondary" &&
    color === "red" &&
    content === "label only" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-secondary text-red-04 bg-red-05 cursor-not-allowed");
  if (variant === "secondary" && color === "red" && loading === true)
    className.push("btn-secondary text-red-04 bg-red-05 flex items-center cursor-not-allowed");
  // button variant=outlined, color=red, content=label only and icont infront
  if (
    variant === "outlined" &&
    color === "red" &&
    content === "label only" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-outlined text-red-03 border-red-03 hover:border-red-02 hover:text-red-02 active:text-red-01 active:border-red-01"
    );
  if (
    variant === "outlined" &&
    color === "red" &&
    content === "label only" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-outlined text-red-04 border-red-04 cursor-not-allowed");
  if (variant === "outlined" && color === "red" && loading === true)
    className.push("btn-outlined text-red-04 border-red-04 flex items-center cursor-not-allowed");
  // button variant=link, color=red, content=label only and icont infront
  if (
    variant === "link" &&
    color === "red" &&
    content === "label only" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-link text-red-03 outline-none focus:outline-none hover:text-red-02 active:text-red-01"
    );
  if (
    variant === "link" &&
    color === "red" &&
    content === "label only" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-link text-red-04 cursor-not-allowed");
  if (variant === "link" && color === "red" && loading === true)
    className.push("btn-link text-red-04 flex items-center cursor-not-allowed");
  // button variant=primary, color=red, content=label only and icont infront
  if (
    variant === "primary" &&
    color === "red" &&
    content === "icon infront" &&
    disabled === false &&
    loading === false
  )
    className.push("btn-primary bg-red-03 hover:bg-red-02 active:bg-red-01 flex items-center");
  if (
    variant === "primary" &&
    color === "red" &&
    content === "icon infront" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-primary bg-red-04 flex items-center cursor-not-allowed");
  if (variant === "primary" && color === "red" && content === "icon infront" && loading === true)
    className.push("btn-primary bg-red-04 flex items-center");
  // button variant=secondary, color=red, content=label only and icont infront
  if (
    variant === "secondary" &&
    color === "red" &&
    content === "icon infront" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-secondary text-red-03 hover:text-red-02 active:text-red-01 bg-red-05 flex items-center"
    );
  if (
    variant === "secondary" &&
    color === "red" &&
    content === "icon infront" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-secondary text-red-04 bg-red-05 flex items-center cursor-not-allowed");
  if (variant === "secondary" && color === "red" && content === "icon infront" && loading === true)
    className.push("btn-secondary text-red-04 bg-red-05 flex items-center");
  // button variant=outlined, color=red, content=label only and icont infront
  if (
    variant === "outlined" &&
    color === "red" &&
    content === "icon infront" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-outlined text-red-03 border-red-03 hover:border-red-02 hover:text-red-02 active:text-red-01 active:border-red-01 flex items-center"
    );
  if (
    variant === "outlined" &&
    color === "red" &&
    content === "icon infront" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-outlined text-red-04 border-red-03 flex items-center cursor-not-allowed");
  if (variant === "outlined" && color === "red" && content === "icon infront" && loading === true)
    className.push("btn-outlined text-red-04 border-red-03 flex items-center");
  // button variant = link, color = red, content = label only and icont infront
  if (
    variant === "link" &&
    color === "red" &&
    content === "icon infront" &&
    disabled === false &&
    loading === false
  )
    className.push("btn-link text-red-03 hover:text-red-02 flex items-center");
  if (
    variant === "link" &&
    color === "red" &&
    content === "icon infront" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-link text-red-04 cursor-not-allowed flex items-center");
  if (variant === "link" && color === "red" && content === "icon infront" && loading === true)
    className.push("btn-link text-red-04 flex items-center cursor-not-allowed flex items-center");
  // button variant = primary, color = red, content = label only and icont behind
  if (
    variant === "primary" &&
    color === "red" &&
    content === "icon behind" &&
    disabled === false &&
    loading === false
  )
    className.push("btn-primary bg-red-03 hover:bg-red-02 active:bg-red-01 flex items-center");
  if (
    variant === "primary" &&
    color === "red" &&
    content === "icon behind" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-primary bg-red-04 flex items-center cursor-not-allowed");
  if (variant === "primary" && color === "red" && content === "icon behind" && loading === true)
    className.push("btn-primary bg-red-04 flex items-center");
  // button variant = secondary, color = red, content = label only and icont behind
  if (
    variant === "secondary" &&
    color === "red" &&
    content === "icon behind" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-secondary text-red-03 hover:text-red-02 active:text-red-01 bg-red-05 flex items-center"
    );
  if (
    variant === "secondary" &&
    color === "red" &&
    content === "icon behind" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-secondary text-red-04 bg-red-05 flex items-center cursor-not-allowed");
  if (variant === "secondary" && color === "red" && content === "icon behind" && loading === true)
    className.push("btn-secondary text-red-04 bg-red-05 flex items-center");
  // button variant = outlined, color = red, content = label only and icont behind
  if (
    variant === "outlined" &&
    color === "red" &&
    content === "icon behind" &&
    disabled === false &&
    loading === false
  )
    className.push(
      "btn-outlined text-red-03 hover:text-red-02 hover:border-red-02 active:border-red-01 active:text-red-01 border-red-03 bg-white flex items-center"
    );
  if (
    variant === "outlined" &&
    color === "red" &&
    content === "icon behind" &&
    disabled === true &&
    loading === false
  )
    className.push(
      "btn-outlined text-red-04 border-red-03 border bg-white flex items-center cursor-not-allowed"
    );
  if (variant === "outlined" && color === "red" && content === "icon behind" && loading === true)
    className.push("btn-outlined text-red-04 border-red-03 flex items-center");
  // button variant = outlined, color = red, content = label only and icont behind
  if (
    variant === "link" &&
    color === "red" &&
    content === "icon behind" &&
    disabled === false &&
    loading === false
  )
    className.push("btn-link text-red-03 hover:text-red-02 active:text-red-01 flex items-center");
  if (
    variant === "link" &&
    color === "red" &&
    content === "icon behind" &&
    disabled === true &&
    loading === false
  )
    className.push("btn-link text-red-04 cursor-not-allowed flex items-center");
  // button variant = primary, color = red, content = icon only
  if (
    variant === "primary" &&
    color === "red" &&
    content === "icon only" &&
    loading === false &&
    disabled === false
  )
    className.push("btn-primary p-3 bg-red-03 hover:bg-red-02 active:bg-red-01");
  if (variant === "primary" && color === "red" && content === "icon only" && disabled === true)
    className.push("btn-primary p-3 bg-red-04 cursor-not-allowed");
  if (variant === "primary" && color === "red" && content === "icon only" && loading === true)
    className.push("btn-primary p-3 bg-red-04 cursor-not-allowed");
  // button variant = secondary, color = red, content = icon only
  if (
    variant === "secondary" &&
    color === "red" &&
    content === "icon only" &&
    loading === false &&
    disabled === false
  )
    className.push("btn-secondary p-3 bg-red-05 text-red-03 hover:text-red-02 active:text-red-01");
  if (variant === "secondary" && color === "red" && content === "icon only" && disabled === true) {
    className.push("btn-secondary p-3 text-red-04 bg-red-05 cursor-not-allowed");
    console.log("masuk");
  }
  if (variant === "secondary" && color === "red" && content === "icon only" && loading === true)
    className.push("btn-secondary p-3 text-red-04 bg-red-05 cursor-not-allowed");
  // button variant = outlined, color = red, content = icon only
  if (
    variant === "outlined" &&
    color === "red" &&
    content === "icon only" &&
    loading === false &&
    disabled === false
  )
    className.push(
      "btn-outlined p-3 text-red-03 border-red-03 hover:text-red-02 active:text-red-01  hover:border-red-02 active:border-red-01"
    );
  if (variant === "outlined" && color === "red" && content === "icon only" && disabled === true)
    className.push("btn-outlined p-3 text-red-04 border-red-04 cursor-not-allowed");
  if (variant === "outlined" && color === "red" && content === "icon only" && loading === true)
    className.push("btn-outlined p-3 text-red-04 border-red-04 cursor-not-allowed");
  // button variant = link, color = red, content = icon only
  if (
    variant === "link" &&
    color === "red" &&
    content === "icon only" &&
    loading === false &&
    disabled === false
  )
    className.push(
      "btn-link p-3 bg-white text-red-03 hover:text-red-02 active:text-red-01 duration-500 hover:border-red-02 active:border-red-01 focus:outline-none outline-none"
    );
  if (variant === "link" && color === "red" && content === "icon only" && disabled === true)
    className.push(
      "btn-link p-3 rounded-lg cursor-pointer bg-white text-red-04 outline-none cursor-not-allowed"
    );
  if (variant === "link" && color === "red" && content === "icon only" && loading === true)
    className.push(
      "btn-link p-3 rounded-lg cursor-pointer bg-white text-red-04  outline-none cursor-not-allowed"
    );
  // ============================== END:BUTTON RED =========================== \\

  if (type === "link") {
    if (external) {
      return (
        <a
          href={href}
          className={className.join(" ")}
          target={target === "_blank" ? "_blank" : undefined}
          rel={target === "_blank" ? "noopener noreferrer" : undefined}
        >
          {children}
        </a>
      );
    } else {
      if (isSubmenu) {
        return (
          <Link
            style={{ paddingLeft: "38px" }}
            to={href}
            className={className.join(" ")}
            onClick={onClick}
            disabled={disabled}
          >
            {children}
          </Link>
        );
      } else {
        return (
          <Link to={href} className={className.join(" ")} onClick={onClick} disabled={disabled}>
            {children}
          </Link>
        );
      }
    }
  }

  if (type === "menu") {
    return (
      <Link to={href} className={className.join(" ")} onClick={onClick} disabled={disabled}>
        <div className="mr-2">
          <Icons iconName={iconName} />
        </div>
        {children}
      </Link>
    );
  }

  if (type === "btnMenu") {
    return (
      <Link to={href} className={className.join(" ")} onClick={onClick} disabled={disabled}>
        <div className="mr-2">
          <Icons iconName={iconName} />
        </div>
        {children}
      </Link>
    );
  }

  return (
    <button
      className={className.join(" ")}
      form={form}
      onClick={onClick}
      disabled={disabled ? disabled : loading ? loading : false}
      type={type}
    >
      {!loading ? (
        <>
          {content === "label only" && children}
          {content === "icon only" && (
            <Icon iconName={iconName} iconSize={iconSize} iconColor={iconColor} />
          )}
          {content === "icon behind" && (
            <>
              {children}
              <div className="ml-1">
                <Icon iconName={iconName} />
              </div>
            </>
          )}
          {content === "icon infront" && (
            <>
              <div className="mr-2">
                <Icon iconName={iconName} />
              </div>
              {children}
            </>
          )}
        </>
      ) : content === "icon behind" ? (
        <>
          {content === "icon only" ? "" : children}
          <div className={`animate-spin ${isBlock ? "w-full" : ""}`}>
            <CgSpinnerAlt />
          </div>
        </>
      ) : (
        <>
          <div className={`animate-spin mr-1`}>
            <CgSpinnerAlt />
          </div>
          {content === "icon only" ? "" : children}
        </>
      )}
    </button>
  );
};

Button.defaultProps = {
  size: ButtonSizes.sm,
  disabled: false,
  loading: false,
  color: "blue",
  content: "label only",
};

Button.propTypes = {
  type: propTypes.string,
  variant: propTypes.oneOf(["primary", "secondary", "outlined", "link"]),
  color: propTypes.oneOf(["blue", "red", "gray", "green"]),
  content: propTypes.oneOf(["label only", "icon infront", "icon behind", "icon only"]),
  iconColor: propTypes.string,
  iconSize: propTypes.number,
  iconName: propTypes.string,
  // size: propTypes.oneOf(['sm', 'md', 'lg']),
  disabled: propTypes.bool,
  onClick: propTypes.func,
  external: propTypes.bool,
  href: propTypes.string,
  target: propTypes.string,
  className: propTypes.string,
  isBlock: propTypes.bool,
};

export default Button;
