import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Avatar from 'react-avatar'

export default function Dropdown({ type, options, onOptionSelect, value }) {
  // Keep track of whether the dropdown is open or not.
  const [isActive, setActive] = React.useState(false);

  const buttonClasses = `inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-blue-500 active:text-gray-200 transition ease-in-out duration-150`;

  const name = localStorage.getItem("username");
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username")
    window.location.reload()
  }

  return (
    <>
      {type === "header" ? (
        <div className="relative">
          <button
            onClick={() => setActive(!isActive)}
            className="text-blue-hedgehog  font-semibold focus:outline-none"
          >
            {/* 1. check type dropdown bila header makan ambil state dari redux bila buka type header tampilin default value */}
            {/* {type === "header" ? "Ethos Inc." : value} */}
            <Avatar name={name}  size="30" value="." round="50px" color="#000D23" alt="Visuo"/>
          </button>
          <div className={`origin-top-right right-0 absolute mt-4 w-56 rounded-md shadow-lg ${isActive ? 'block' : 'hidden'}`}>
            <div className="rounded-md bg-white shadow-xs">
              <div className="py-1">
                <Link to="/pengaturan" className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">Pengaturan Akun</Link>
                {/* <Link to="/" className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">Instruksi visuo</Link> */}
                <div onClick={handleLogout} className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 cursor-pointer">Logout</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <button onClick={() => setActive(!isActive)} className={buttonClasses}>
            {/* 1. check type dropdown bila header makan ambil state dari redux bila buka type header tampilin default value */}
            {type === "header" ? "user login" : value}
          </button>

          <div  className={`origin-top-right absolute mt-2 w-56 rounded-md shadow-lg ${isActive ? "block" : "hidden" }`} >
            {options.map((option) => (
              <div
                key={option}
                className="rounded-md bg-white shadow-xs block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                onClick={(e) => onOptionSelect(option)}
              >
                {option}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}

Dropdown.defaultProps = {
  type: "input",
};

Dropdown.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
};
