import * as React from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/atoms/Button";
import DropDown from "components/atoms/Dropdown";
import Leftbar from "../Leftbar";
import Logo from "components/atoms/Logo/Logo.svg";
import { handleMenuSidebar } from "features/Other/actions";

function TopBar({ location }) {
  const dispatch = useDispatch();
  const [position, setPosition] = React.useState(false);
  const showLeftbar = () => setPosition(!position);

  const { showMenuSidebar } = useSelector((state) => state.other);

  return (
    <div className="topbar">
      <div className="flex self-center">
        <Leftbar position={position ? "leftbar-transition" : ""} setHide={showLeftbar} />
        <div className="flex pr-5 self-center">
          <Button variant="secondary" color="gray" content="icon only" iconName="FaBars" />
          <p className="title">{location}</p>
        </div>
      </div>

      <div className="self-center flex justify-center">
        <img src={Logo} alt="logo" />
      </div>

      <div className="flex self-center items-center justify-end">
        <Button onClick={showLeftbar} content="label only" variant="primary" color="green">
          Panduan Visuo
        </Button>
        <div className="flex mx-3">
          {/* <Button
            variant="secondary"
            color="gray"
            content="icon only"
            iconName="FaSistrix"
            className="mr-2"
          />
          <Button
            iconName="FaRegBell"
            variant="secondary"
            color="gray"
            content="icon only"
            className="mr-2"
          /> */}
          <Button
            iconName="FaRegQuestionCircle"
            variant="secondary"
            color="gray"
            content="icon only"
          />
        </div>
        <DropDown type="header" value="Ethos Inc." />
      </div>
    </div>
  );
}

export default TopBar;
