import React from 'react'
import "assets/stylesheets/spinner.css"

export default function SpinnerTable() {
    return (
        <div className="loader">
            
        </div>
    )
}
