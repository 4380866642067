import * as React from "react";
import Topbar from "components/organisms/Topbar";
import Topbar_2 from "components/organisms/Topbar/TopHeader_2";
import { useLocation } from "react-router-dom";
import Tab from "components/organisms/Tab";
import menus from "./menus";
import Fade from "react-reveal/Fade";

function App({ children, type }) {
  let location = useLocation();
  location = location.pathname.split("/");
  location = location[1].charAt(0).toUpperCase() + location[1].substr(1).toLowerCase();
  let [tab, setTab] = React.useState([]);

  React.useEffect(() => {
    const checkMenu = () => {
      const menu = [];
      menus.forEach((data) => {
        // if (data.prefix === location) {
        menu.push(data);
        // }
      });
      setTab(menu);
    };
    checkMenu();
  }, []);

  if (type === "add") {
    return (
      <Fade bottom>
        <div className="relative w-full h-screen">
          <div className="absolute w-full">
            <Topbar_2 location={location} />
          </div>
          <div className="flex">{children}</div>
        </div>
      </Fade>
    );
  } else {
    return (
      <div className="relative w-full h-screen">
        <div className="absolute w-full">
          <Topbar location={location} />
        </div>
        <div className="flex flex-wrap">
          <div className="w-2/12 mx-4">
            <Tab children={tab} />
          </div>
          {children}
        </div>
      </div>
    );
  }
}

export default App;
