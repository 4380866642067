// button types = color
export const ButtonTypes = {
  primary: "text-white rounded",
  secondary: "secondary /CSS",
  outlined: "outlined /CSS",
  link: "link / CSS",
};

export const ButtonSizes = {
  sm: "py-1 px-4 text-sm font-medium",
  md: "py-2 px-8",
  lg: "py-4 px-12",
};

export const ButtonColors = {
  primaryBlue100: "bg-blue-500 hover:bg-blue-700 text-white border-blue-700",
  primaryBlue200: "bg-blue-600 hover:bg-blue-800 text-white border-blue-800",
  primaryBlue300: "",
};
