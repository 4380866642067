import {
  START_FETCHING_ACCESS_HOUR,
  SUCCESS_FETCHING_ACCESS_HOUR,
  ERROR_FETCHING_ACCESS_HOUR,
  SET_NOTIF,
  SET_KEYWORD,
  SET_PAGE,
  SET_LIMIT,
} from "./constants";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: [],
  status: statuslist.idle,
  keyword: "",
  page: 0,
  limit: 10,
  totalPage: 0,
  notif: {
    title_flag: "",
    message: ``,
    bg_flag: "bg-green-02",
    bg_btn: "bg-green-03",
    icon: "FaRegCheckCircle",
    toggle: false,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_ACCESS_HOUR:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_ACCESS_HOUR:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_ACCESS_HOUR:
      return {
        ...state,
        status: statuslist.success,
        data: action.accessHour,
      };

    case SET_NOTIF:
      return { ...state, notif: action.notif };

    case SET_KEYWORD:
      return { ...state, keyword: action.keyword };
    case SET_PAGE:
      return { ...state, page: action.page };
    case SET_LIMIT:
      return { ...state, limit: action.limit };

    default:
      return state;
  }
}
